<template>
  <div class="achievements">
    <div v-for="(item, i) in list" :key="i" class="achievements__item">
      <IconComponent :name="item.icon" />
      <div class="achievements__item-info">
        <span class="achievements__item-title">{{ item.title }}</span>
        <span class="achievements__item-subtitle">{{ item.subtitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "AchievementsComponent",
  components: { IconComponent },
  computed: {
    list() {
      return [
        {
          icon: "like-shapes",
          title: "с 2003",
          subtitle: "года на рынке",
        },
        // {
        //   icon: "3d-square",
        //   title: this.$store.state.global.info?.count_products || 0,
        //   subtitle: "товаров в наличии",
        // },
        {
          icon: "medal-star",
          title: "EU",
          subtitle: "Евростандарты",
        },
      ];
    },
  },
};
</script>

<style lang="stylus">
.achievements {
  display flex
  gap: var(--gap)
  justify-content center
  +below(660px) {
    gap: 15px
  }
  +below(380px) {
    display grid
    grid-template-columns 1fr
  }

  &__item {
    flex 1
    padding 17px
    display flex
    gap: 10px
    justify-content center
    align-items center
    text-align center
    background: var(--white)
    border-radius: var(--big-radius)
    +above(1025px) {
			max-width: 30%;
		}
		+below(800px) {
      flex-direction column
      padding 15px 10px
      gap: 15px
      justify-content flex-start
    }

    &-info {
      display flex
      flex-direction column
      +below(800px) {
        align-items center
      }
    }

    .icon {
      width: 60px
      height: 60px
      +below(580px) {
        width: 50px
        height: 50px
      }
    }

    &-title {
      font-weight 700
      font-size: 1.375em
      text-transform: uppercase;
      line-height: 28px;
      +below(540px) {
        font-size: 1.125em
      }
    }

    &-subtitle {
      line-height: 14px;
      +below(480px) {
        font-size 0.75em
      }
    }
  }
}
</style>
