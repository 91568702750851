<template>
  <div class="header-top">
    <div class="header-top__container container-padding">
      <div class="header-top__contacts">
        <ContactComponent :data="email" />
        <ContactComponent :data="phone" />
      </div>
      <span class="header-top__title">Сеть ортопедических салонов</span>
      <div class="header-top__links">
        <router-link class="header-top__link" :to="link.route" v-for="(link, i) in links" :key="i">
          {{ link.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import LoginModal from "components/modals/components/LoginModal.vue";
import ContactComponent from "components/ContactComponent.vue";

export default {
  name: "HeaderTopComponent",
  data() {
    return {
      links: [
        {
          title: "Адреса и филиалы",
          route: { name: "shops" },
        },
        {
          title: "Акции",
          route: { name: "shares" },
        },
        {
          title: "Помощь",
          route: { name: "static", params: { id: 4 } },
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    communications() {
      return this.$store.state.global.info?.communications || [];
    },
    phone() {
      return this.communications.find((item) => item.type.code === this.$store.state._types.PHONE);
    },
    email() {
      return this.communications.find((item) => item.type.code === this.$store.state._types.EMAIL);
    },
  },
  components: { ContactComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/link.styl"
.header-top {
  display flex
  align-items center
  justify-content center
  height 60px
  border-bottom 1px solid var(--border-color)
  background var(--bg-color)
  +below(1150px) {
    display none
  }

  &__container {
    display flex
    align-items center
    justify-content space-between
    gap 8px
  }

  &__contacts {
    display flex
    align-items center
    gap 15px
  }

  &__contact {
    display flex
    align-items center
    gap: 5px
    padding 5px 10px
    color inherit

    .icon {
      width: 20px
      height: 20px
    }
  }

  &__title {
    color: var(--dark-light)
  }

  &__links {
    display flex
    align-items center
    gap: 30px
  }

  &__link {
    border-bottom 1px solid
    line-height 1
  }
}
</style>
