<template>
  <div class="small-footer">
    <div class="container-padding">
      <div class="small-footer__inner">
        <span class="small-footer__copyright">
          {{ year }} © Информация на сайте не является публичной офертой. Сеть ортопедических салонов
          «Ортосити».
          <!--          <a href="/files/terms.pdf">Условия использования.</a>-->
        </span>
        <div class="small-footer__bottom">
          <ComradesLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ComradesLogo from "components/ComradesLogo.vue";

export default {
  name: "SmallFooterComponent",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  components: { ComradesLogo, IconComponent },
};
</script>

<style lang="stylus">
.small-footer {
  display: flex;
  align-items center
  border-top 1px solid var(--border-color)
  padding 30px 0
  background var(--white)

  &__inner {
    display flex
    align-items center
    justify-content space-between
    flex-wrap wrap
    flex-direction row
    gap 20px
  }

  &__bottom {
    display flex
    justify-content space-between
    flex-wrap wrap
    gap 20px
		flex-shrink 0
  }

  &__copyright {
    font-size: 0.875em
    line-height: 145%;

    +below(640px) {
      font-size: 0.725em
    }
  }

  &__pay-methods {
    display flex
    align-items center
    gap 10px
  }

  &__pay-method {
    background: var(--gray);
    box-sizing: border-box;
    border-radius: 4px;
    width 50px
    height 36px
    display inline-flex
    align-items center
    justify-content center

    a {
      display inline-flex
      align-items center
      justify-content center

      .icon {
        max-width 43px
        max-height 28px
      }
    }
  }
}
</style>
