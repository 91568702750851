<template>
  <div class="main-footer">
    <div class="container-padding">
      <div class="main-footer__inner">
        <div class="main-footer__top">
          <IconComponent class="main-footer__logo" name="logo" />
          <span class="main-footer__top-text">Сеть ортопедических салонов</span>
        </div>
        <div class="main-footer__columns">
          <nav v-if="categories && categories.length" class="main-footer__column">
            <h4 class="main-footer__column-title">Каталог</h4>
            <ul class="main-footer__column-list">
              <li v-for="(item, i) in categories" :key="i">
                <router-link
                  :to="{ name: 'catalog', params: { id: item.id } }"
                  class="main-footer__column-link"
                >
                  <span>{{ item.title }}</span>
                  <IconComponent name="arrow-right-2" />
                </router-link>
              </li>
            </ul>
          </nav>
          <nav class="main-footer__column">
            <h4 class="main-footer__column-title">Покупателям</h4>
            <ul class="main-footer__column-list">
              <li class="mobile">
                <router-link class="main-footer__column-link" :to="{ name: 'catalog' }">
                  <span> Каталог </span>
                  <IconComponent name="arrow-right-2" />
                </router-link>
              </li>
              <li>
                <router-link class="main-footer__column-link" :to="{ name: 'shares' }">
                  <span> Акции </span>
                  <IconComponent name="arrow-right-2" />
                </router-link>
              </li>
              <li v-for="(page, i) in pages" :key="i">
                <router-link
                  class="main-footer__column-link"
                  :to="{ name: 'static', params: { id: page.id, link: page.link } }"
                >
                  <span> {{ page.title }} </span>
                  <IconComponent name="arrow-right-2" />
                </router-link>
              </li>
            </ul>
          </nav>
          <nav class="main-footer__column main-footer__column-contacts">
            <h4 class="main-footer__column-title">Контакты</h4>
            <div class="main-footer__column-list">
              <div v-if="contacts && contacts.length" class="main-footer__column-contacts-wrap">
                <ContactComponent v-for="(item, i) in contacts" :key="i" :data="item" />
              </div>
              <router-link class="main-footer__column-link" :to="{ name: 'shops' }">
                <span>Адреса и филиалы</span>
                <IconComponent name="arrow-right-2" />
              </router-link>
              <SocialsComponent />
              <span v-if="socials && socials.length" class="main-footer__socials-note">
                *Компания Meta, которой принадлежит Instagram, Facebook, признана в РФ экстремистской
                организацией.
              </span>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import SocialsComponent from "components/SocialsComponent.vue";
import ContactComponent from "components/ContactComponent.vue";

export default {
  name: "MainFooterComponent",
  computed: {
    pages() {
      return this.$store.state.global.pages || [];
    },
    categories() {
      return this.$store.state.global.footer_categories;
    },
    contacts() {
      let types = this.$store.state._types;
      if (
        this.$store.state.global.info.communications &&
        this.$store.state.global.info.communications.length
      ) {
        return this.$store.state.global.info?.communications.filter(
          (item) => ![types.SOCIAL, types.ADDRESS].includes(item.type.code)
        );
      }
      return [];
    },
    socials() {
      if (
        this.$store.state.global.info &&
        this.$store.state.global.info.communications &&
        this.$store.state.global.info.communications.length
      ) {
        return this.$store.state.global.info.communications.filter(
          (item) => this.$store.state._types.SOCIAL === item.type.code
        );
      }
      return [];
    },
  },
  components: { ContactComponent, SocialsComponent, IconComponent },
};
</script>

<style lang="stylus">
.main-footer {
  display flex
  justify-content center
  padding 40px 0 60px
  background var(--white)
  +below(540px) {
    padding 30px 0
  }

  &__inner {
    display flex
    flex-direction column
    gap: 30px
  }

  &__top {
    display: flex;
    align-items center
    gap var(--gap)
    +below(540px) {
      display none
    }

    &-text {
      color: var(--dark-light);
      line-height 1.38
      max-width 167px
      +below(768px) {
        max-width none
      }
    }

    &::after {
      content ""
      display block
      flex-grow 1
      height 2px
      background var(--blue)
      border-radius 2px
      +below(768px) {
        display none
      }
    }
  }

  &__logo {
    width: 200px;
    height: auto;
  }

  &__columns {
    display grid
    grid-template-columns 2fr 1fr 1fr
    grid-gap var(--gap)
    width 100%
    +below(1200px) {
      grid-template-columns 1fr 1fr
      grid-gap 30px
    }
    +below(768px) {
      grid-template-columns 1fr
    }
    +below(540px) {
      grid-gap 15px
    }
  }

  &__column {
    display flex
    flex-direction column
    gap: 10px

    &:first-child {
      +below(1200px) {
        grid-column: auto / span 2;
      }
      +below(768px) {
        grid-column: auto
      }
      +below(640px) {
        display none
      }
    }

    &:first-child &-list {
      //column-count: 2;
      column-gap var(--gap)
      display grid
      grid-template-columns 1fr 1fr

      li {
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

    &:not(:last-child) &-title {
      +below(640px) {
        display none
      }
    }

    &-contacts &-list {
      gap: 10px
    }

    &-contacts-wrap {
      display flex
      flex-direction column
      gap: 5px
    }
  }

  &__column-title {
    font-weight 400
    font-size: 1.125em
    line-height: 1.55
    margin-bottom 0
    padding 15px
    border-bottom 1px solid var(--border-color)
    text-transform none
    +below(420px) {
      font-size 1em
    }
  }

  &__column-list {
    display flex
    flex-direction column
    justify-content flex-start

    li.mobile {
      +above(641px) {
        display none
      }
    }
  }

  &__column-link {
    font-weight: normal;
    font-size: 0.875em
    line-height: 145%;
    display: flex;
    align-items: center;
    justify-content space-between
    gap: 10px
    padding 10px 15px
    color var(--dark-light)

    .icon {
      width 16px
      height 16px
    }
  }

	&__note {
		font-size 0.875rem
		line-height 1.2
	}
}
</style>
